import { useState, useEffect } from "react";
import Button from "../components/buttons/Button";
import ContentWrapper from "../components/general/ContentWrapper";
import ProjectHero from "../components/ProjectHero";
import SecondaryPage from "../components/general/SecondaryPage";
import hero from "../assets/imgs/mochi/hero.png";
import TLDR from "../components/mochi/TLDR";
import { IoArrowForwardSharp } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { IoArrowBackSharp } from "react-icons/io5";

function Mochi() {
  return (
    <SecondaryPage>
      <ProjectHero src={hero} />
      <ContentWrapper>
        <TLDR />
      </ContentWrapper>
    </SecondaryPage>
  );
}

export default Mochi;
